<template>
  <div class="gg-container-1">

    <div class="search-container">
      <!--输入框-->
      <div class="search-container-fn-input">
        <label>客户查找：</label>
        <el-input
            style="width:200px"
            size="mini"
            placeholder="请输入客户姓名/手机号"
            prefix-icon="el-icon-search"
            v-model="searchParams.keywords"
            clearable
            @change="getList('restPage')"
        >
        </el-input>
      </div>
      <el-button
          size="mini"
          type="primary"
          style="padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
          @click="getList('restPage')"
      >
        <span>查找</span>
      </el-button>

      <div style="margin-left: auto; display: flex; justify-content: space-around">

        <div class="search-container-fn-input"
             v-if="operateList.indexOf('update_table') > -1"
        >
          <el-button size="mini" type="primary" icon="el-icon-edit-outline"
                     style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
                     @click="handleEditTableHead">编辑表头
          </el-button>
        </div>

        <div class="search-container-fn-input"
             v-if="operateList.indexOf('reset_query') > -1"
        >
          <el-button size="mini" type="primary" icon="el-icon-refresh"
                     style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
                     @click="handleResetSearch">重置查找
          </el-button>
        </div>


      </div>

    </div>

    <!--<div class="search-container">
      &lt;!&ndash;输入框&ndash;&gt;
      <div class="search-container-fn-input">
        <label>客户查找：</label>
        <el-input
          style="width:200px"
          size="small"
          placeholder="请输入客户姓名/手机号"
          prefix-icon="el-icon-search"
          v-model="searchParams.keywords"
          clearable
          @change="getList('restPage')"
        >
        </el-input>
      </div>
      <div class="search-container-fn-input">
        <label>订单状态：</label>
        <el-select
          style="width:200px"
          v-model="searchParams.order_status"
          placeholder="请选择"
          size="small"
          clearable
          @change="getList('restPage')"
        >
          <template slot="prefix">
            <i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
          </template>
          <el-option
            v-for="item in orderStatusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>

      <div class="search-container-fn-input">
        <label>下单时间：</label>
        <el-date-picker
          v-model="choiceDate"
          type="daterange"
          size="small"
          value-format="yyyy-MM-dd HH-mm-ss "
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="handleChangeDate">
        </el-date-picker>
      </div>

    </div>
    -->
    <!--<div class="search-container">

      <div class="search-container-fn-input">
        <label>下单人员：</label>
        <el-select
          style="width:200px"
          v-model="searchParams.staff_id"
          placeholder="下单人员"
          size="small"
          clearable
          @change="getList('restPage')"
        >
          <template slot="prefix">
            <i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
          </template>
          <el-option
            v-for="item in staffOptions"
            :key="item.id"
            :label="item.staff_name"
            :value="item.id">
          </el-option>
        </el-select>
      </div>


      <div class="search-container-fn-input">
        <label>客户状态：</label>
        <el-cascader
          clearable
          :options="customerStatusOptions"
          size="small"
          placeholder="请选择客户状态"
          :props="{ expandTrigger: 'hover' }"
          @change="handleChangeCustomerStatus">
        </el-cascader>
      </div>
      <div style="margin-left: auto; margin-right: 30px; color: #2632fb">
        金额汇总: {{priceTotal}}
      </div>
    </div>-->
    <!----------------表格---------------->
    <!--<el-table
      border
      v-loading="listLoading"
      :header-cell-style="{'text-align':'center'}"
      height="625"
      :data="tableData"
      style="width: 100%;z-index:0">
      <el-table-column
        prop="created_at"
        label="下单时间"
        align="center">
      </el-table-column>
      <el-table-column
        prop="order_sn"
        label="订单号码"
        align="center">
        <template slot-scope="scope">

          <a style="color: #2632fb" @click="handleLogisticsDetail(scope.row.order_id)">{{scope.row.order_sn}}</a>
        </template>
      </el-table-column>
      <el-table-column
        prop="actual_amount"
        label="订单金额"
        align="center">
      </el-table-column>
      <el-table-column
        prop="use_day"
        label="可用天数"
        align="center">
      </el-table-column>
      <el-table-column
        prop="order_status_name"
        label="订单状态"
        align="center">
      </el-table-column>
      <el-table-column
        prop="real_name"
        label="客户姓名"
        align="center">
      </el-table-column>
      <el-table-column
        prop="contract_status_name"
        label="客户状态"
        align="center">
      </el-table-column>
      <el-table-column
        prop="staff_name"
        label="下单人员"
        align="center">
      </el-table-column>
    </el-table>-->
    <el-table
        border
        size="mini"
        v-loading="listLoading"
        :header-cell-style="{'text-align':'center'}"
        :data="tableData"
        height="625"
        style="width: 100%;z-index:0">
      <el-table-column
          type="index"
          align="center"
          width="50">
      </el-table-column>
      <template
          v-for="(column,index) in tableHead"
      >
        <el-table-column
            :prop="column.column_prop"
            :label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
            :width="column.width ? column.width : '' "
            :key="index"
            align="center"
            v-if="column.field_type === 'textBtn' && column.visible === true "
            show-overflow-tooltip
        >
          <!--<template #header>
            <el-popover placement="bottom" title="" width="230" trigger="hover">
              <div slot="reference" class="search-header">
                <span class="search-title">{{column.column_label_user_definition ? column.column_label_user_definition : column.column_label}}</span>
                <i style="margin-left: 5px" class="search-icon el-icon-search"></i>
              </div>
              <el-input
                  size="mini"
                  placeholder=""
                  prefix-icon="el-icon-search"
                  v-model="searchParams.buy_count"
                  clearable
                  @change="getList('restPage')"
                >
                </el-input>
            </el-popover>
          </template>-->
          <template slot-scope="scope">
            <a style="font-size: 12px; color: #2379fb"
               @click.prevent="handleOrderDetail(scope.row)">
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
        </el-table-column>
        <el-table-column
            :prop="column.column_prop"
            :label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
            :width="column.width ? column.width : '' "
            :key="index"
            align="center"
            v-else-if="column.field_type === 'imageBtn' && column.visible === true "
            show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div v-if="scope.row[column.column_prop].length>0" style="font-size: 12px; color: #2379fb"
                 @click="handleShowImage(scope.row[column.column_prop],  scope.row[column.column_prop][0])">
              <a>点击查看图片</a>
              <el-image
                  style="width: 0px; height: 0px"
                  :ref="scope.row[column.column_prop][0]"
                  :src="imageUrl"
                  :preview-src-list="scope.row[column.column_prop]"
                  :z-index="9999">
              </el-image>
            </div>
            <div v-else>
              暂无
            </div>
          </template>
        </el-table-column>
        <el-table-column
            :sortable="tableHeadSortable.includes(column.column_prop)"
            :prop="column.column_prop"
            :label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
            :width="column.width ? column.width : '' "
            :key="index"
            align="center"
            v-else-if="column.visible === true"
            show-overflow-tooltip
        >
          <template #header>
            <el-popover placement="bottom" title="" min-width="160" trigger="click"
                        v-if="searchTableHead.filter( item => item.name == column.column_prop).length > 0">
							<span slot="reference" class="search-header">
								<span
                    class="search-title"
                >{{ column.column_label_user_definition ? column.column_label_user_definition : column.column_label }}</span>
								<i style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                   class="el-icon-search"
                   :class="{isSearch: searchTableHead.filter(item => item.name == column.column_prop)[0].isSearch,
									  'el-icon-zoom-in': searchTableHead.filter(item => item.name == column.column_prop)[0].isSearch}"
                />
							</span>
              <el-input
                  v-if="'order_count' == column.column_prop"
                  size="mini"
                  placeholder=""
                  prefix-icon="el-icon-search"
                  v-model="searchParams.buy_count"
                  clearable
                  @change="handleSearch(column.column_prop ,searchParams.buy_count)"
              >
              </el-input>


              <el-select
                  v-else-if="'order_status_name' == column.column_prop"
                  style="width:200px"
                  v-model="searchParams.order_status"
                  placeholder="请选择"
                  size="small"
                  clearable
                  @change="handleSearch(column.column_prop ,searchParams.order_status)"
              >
                <template slot="prefix">
                  <i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
                </template>
                <el-option
                    v-for="item in orderStatusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>

              <el-cascader
                  v-else-if="'contract_status_name' == column.column_prop"
                  clearable
                  :options="customerStatusOptions"
                  size="small"
                  placeholder="请选择客户状态"
                  :props="{ expandTrigger: 'hover' }"
                  @change="handleChangeCustomerStatus">
              </el-cascader>

              <el-select
                  v-else-if="'visit_level' == column.column_prop"
                  style="width:200px"
                  v-model="searchParams.visit_level"
                  placeholder="请选择"
                  size="mini"
                  clearable
                  @change="handleSearch(column.column_prop ,searchParams.visit_level)"
              >
                <template slot="prefix">
                  <i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
                </template>
                <el-option
                    v-for="item in optionsVisitLevel"
                    :key="item.short_name"
                    :label="item.short_name"
                    :value="item.short_name">
                </el-option>
              </el-select>


              <el-select
                  v-else-if="'from' == column.column_prop"
                  style="width:200px"
                  v-model="searchParams.from_id"
                  placeholder="请输入客户来源"
                  size="small"
                  clearable
                  @change="handleSearch(column.column_prop ,searchParams.from_id)"
              >
                <template slot="prefix">
                  <i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
                </template>
                <el-option
                    v-for="item in customerFromOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>

              <el-date-picker
                  v-else-if="'created_at' == column.column_prop"
                  v-model="choiceDate"
                  :default-time="['00:00:00', '23:59:59']"
                  type="daterange"
                  size="mini"
                  value-format="yyyy-MM-dd HH-mm-ss "
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleChangeDate()">
              </el-date-picker>

              <el-date-picker
                  v-else-if="'expired_at' == column.column_prop"
                  v-model="choiceDateExpire"
                  :default-time="['00:00:00', '23:59:59']"
                  type="daterange"
                  size="mini"
                  value-format="yyyy-MM-dd HH-mm-ss "
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleExpireChangeDate">
              </el-date-picker>

              <el-date-picker
                  v-else-if="'end_visit_at' == column.column_prop"
                  v-model="choiceDateEndVisit"
                  :default-time="['00:00:00', '23:59:59']"
                  type="daterange"
                  size="mini"
                  value-format="yyyy-MM-dd HH-mm-ss "
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleEndVisitChangeDate">
              </el-date-picker>

              <el-date-picker
                  v-else-if="'end_order_at' == column.column_prop"
                  v-model="choiceDateEndOrder"
                  :default-time="['00:00:00', '23:59:59']"
                  type="daterange"
                  size="mini"
                  value-format="yyyy-MM-dd HH-mm-ss "
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleEndOrderChangeDate">
              </el-date-picker>


              <el-date-picker
                  v-else-if="'next_visit_at' == column.column_prop"
                  v-model="choiceDateNextVisit"
                  :default-time="['00:00:00', '23:59:59']"
                  type="daterange"
                  size="mini"
                  value-format="yyyy-MM-dd HH-mm-ss "
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleNextVisitChangeDate">
              </el-date-picker>

              <el-select
                  v-else-if="'staff_name' == column.column_prop"
                  style="width:200px"
                  v-model="searchParams.staff_id"
                  placeholder="请选择"
                  size="mini"
                  clearable
                  @change="handleSearch(column.column_prop ,searchParams.staff_id)"
              >
                <template slot="prefix">
                  <i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
                </template>
                <el-option
                    v-for="item in staffOptions"
                    :key="item.id"
                    :label="item.staff_name"
                    :value="item.id">
                </el-option>
              </el-select>

            </el-popover>
            <span
                v-else>{{ column.column_label_user_definition ? column.column_label_user_definition : column.column_label }}</span>
          </template>
          <template slot-scope="scope">
            <span v-if="column.column_prop === 'buy_status'">{{
                scope.row[column.column_prop] === 1 ? '未购' : '已购'
              }}</span>
            <span v-else-if="column.column_prop === 'use_status'">
								{{ scope.row[column.column_prop] | useStatusFormat }}
							</span>
            <span v-else-if="column.column_prop === 'is_wx'">
								{{ scope.row[column.column_prop] == '0' ? '否' : '是' }}
							</span>
            <span v-else-if="column.column_prop === 'supervise_status'">
								{{ scope.row[column.column_prop] | formatSuperviseStatus }}
							</span>
            <span v-else>{{ scope.row[column.column_prop] }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <!----------------分页---------------->
    <pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
                @pagination="getList"/>

    <!--查看订单详情弹出框-->
    <dialogOrderDetail :isActiveOrderDetailDialog.sync="isActiveOrderDetailDialog"
                       @getList="getList"
                       :orderId="orderId"
                       v-if="isActiveOrderDetailDialog"
                       width="1200"
    ></dialogOrderDetail>


    <!--编辑表头-->
    <editTableHead :isActiveEditTableHead.sync="isActiveEditTableHead"
                   @_getAdminFieldIndex="_getAdminFieldIndex"
                   v-if="isActiveEditTableHead"
                   :table_type="table_type"
    >

    </editTableHead>

  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import dialogOrderDetail from './components/dialogOrderDetail';
import {getAdminFieldIndex, getLogisticsFollow, getStaffList} from '@/api/ggkq';
import {mapGetters, mapState} from 'vuex';
import editTableHead from '@/components/editTableHead';

export default {
  name: "logisticsFollow",
  components: {
    Pagination,
    dialogOrderDetail,
    editTableHead
  },
  async created() {
    console.log(this.$route.path)
    console.log(this.operates)
    this.operates.forEach(operate => {
      if (operate[this.$route.path]) {
        console.log(operate[this.$route.path].split(','))
        this.operateList.push(...operate[this.$route.path].split(','))
      }
    })
    await this._getAdminFieldIndex()
    await this._getStaffList()
    this.getTodayTime()
    this.getList()
  },
  computed: {
    ...mapGetters(["agentId", "adminId"]),
    ...mapState({
      is_admin: state => state.user.is_admin,
      operates: state => state.user.operates,
      table_options: state => state.user.table_options,
    }),
  },
  data() {
    return {
      table_type: 'logisticsFollow',
      searchTableHead: [
        {
          name: 'order_count',
          isSearch: false
        },
        {
          name: 'order_status_name',
          isSearch: false
        },
        {
          name: 'contract_status_name',
          isSearch: false
        },
        {
          name: 'created_at',
          isSearch: true
        },
        {
          name: 'staff_name',
          isSearch: false
        },
      ],
      tableHeadSortable: ['created_at', 'actual_amount', 'use_day'],
      isActiveEditTableHead: false,
      customerFromOptions: [],
      tableHead: [],
      choiceDateCreateRecord: [],
      choiceDate: [],
      searchParams: {
        created_at: [],
      },
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id',
      },
      total: 0,
      listLoading: false,
      // 1=待确认 2=待支付, 3=待发货, 4=待收货, 5=售后中, 7 已完成 8 已取消 9 待审核
      orderStatusOptions: [
        {
          label: '待确认',
          value: '1'
        },
        {
          label: '待支付',
          value: '2'
        },
        {
          label: '待发货',
          value: '3'
        },
        {
          label: '待收货',
          value: '4'
        },
        {
          label: '售后中',
          value: '5'
        },
        {
          label: '已完成',
          value: '7'
        },
        {
          label: '已取消',
          value: '8'
        },
        {
          label: '待审核',
          value: '9'
        },
      ],
      tableData: [],
      staffOptions: [],
      customerStatusOptions: [
        {
          value: '',
          label: '初购',
          children: [
            {
              value: '1',
              label: '咨询',
            },
            {
              value: '2',
              label: '追访',
            },
            {
              value: '6',
              label: '全部',
            },
          ],
        },
        {
          value: '',
          label: '复购',
          children: [
            {
              value: '3',
              label: '在用',
            },
            {
              value: '5',
              label: '停用',
            },
            {
              value: '7',
              label: '全部',
            },
          ],
        }
      ],
      isActiveOrderDetailDialog: false,
      orderId: '',
      priceTotal: '',
      operateList: [],
    }
  },
  methods: {
    handleSearch(prop, value) {
      this.searchTableHead.forEach(item => {
        if (item.name == prop) {
          if (value == '') {
            item.isSearch = false
          } else {
            item.isSearch = true
          }
        }
      })
      this.getList('restPage')
    },
    handleResetSearch() {
      this.searchParams = {
        type: 'no_buy',
        is_wx: '',
        visit_level: '',
        buy_count: '',
      }
      this.searchTableHead.forEach(item => {
        item.isSearch = false
      })
      this.choiceDateCreateRecord = []
      this.choiceDateExpire = []
      this.choiceDateEndVisit = []
      this.choiceDateEndOrder = []
      this.choiceDateNextVisit = []
      this.getList('restPage')
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true
    },
    async _getAdminFieldIndex() {
      //   try {
      //     this.listLoading = true
      //     var params = {
      //       type: 'logisticsFollow'
      //     }
      //     const res = await getAdminFieldIndex(params)
      this.tableHead = this.table_options.logisticsFollow
      //     console.log(this.tableHead)
      //   } catch (err) {
      //     //在此处理错误
      //   } finally {
      //     this.listLoading = false
      //   }

    },

    getTodayTime() {
      var date = new Date();
      // 获取当前月份
      var nowMonth = date.getMonth() + 1;
      // 获取当前是几号
      var strDate = date.getDate();
      // 添加分隔符“-”
      var separator = "-";
      // 对月份进行处理，1-9月在前面添加一个“0”
      if (nowMonth >= 1 && nowMonth <= 9) {
        nowMonth = "0" + nowMonth;
      }
      // 对月份进行处理，1-9号在前面添加一个“0”
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var nowDate = date.getFullYear() + separator + nowMonth + separator + strDate + ' ' + '00:00:00';
      var nowDateLast = date.getFullYear() + separator + nowMonth + separator + strDate + ' ' + '23:59:59';
      this.searchParams.created_at = nowDate + "," + nowDateLast
      this.choiceDate.push(new Date(Date.parse(nowDate)))
      this.choiceDate.push(new Date(Date.parse(nowDateLast)))
    },
    getList(type) {
      if (type == 'restPage') {
        this.listQuery.page = 1
      }
      this.listLoading = true
      this.searchParams.page = this.listQuery.page
      this.searchParams.limit = this.listQuery.limit
      getLogisticsFollow(this.searchParams).then(response => {
        let data = response
        if (data.code == 200) {
          let _data = data.data
          this.total = _data.total
          this.listQuery.limit = _data.per_page
          this.tableData = _data.list
          this.priceTotal = _data.total_amount
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.listLoading = false
      })
    },
    async _getStaffList() {
      var params = {
        agent_id: this.agentId
      }
      var result = await getStaffList(params)
      console.log(result)
      this.staffOptions = result.data
    },
    handleChangeCustomerStatus(value) {
      this.searchParams.contract_status = value[1]
      this.handleSearch('contract_status_name', this.searchParams.contract_status)
      // this.getList('restPage')
    },
    handleLogisticsDetail(id) {
      console.log(id)
      this.orderId = id.toString()
      this.isActiveOrderDetailDialog = true
    },
    handleChangeDate() {
      this.searchParams.start_at = []
      this.searchParams.end_at = []
      this.searchParams.created_at = ""
      console.log(this.choiceDate)
      var _tmp0
      var _tmp1
      if (this.choiceDate) {
        _tmp0 = this.choiceDate[0].substring(0, 10)
        _tmp1 = this.choiceDate[1].substring(0, 10)
        this.searchParams.created_at += _tmp0 + ","
        this.searchParams.created_at += _tmp1
      } else {

        this.searchParams.created_at = ""
      }
      this.searchTableHead.forEach(item => {
        if (item.name == 'created_at') {
          if (this.searchParams.created_at == '') {
            item.isSearch = false
          } else {
            item.isSearch = true
          }
        }
      })
      this.getList('restPage')
      this.getList('restPage')
    },
    handleOrderDetail(order) {
      console.log(order.order_id)
      this.orderId = order.order_id.toString()
      this.order = order
      this.isActiveOrderDetailDialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
.isSearch {
  color: #F56C6C;
}

.search-icon {
  color: #F56C6C;
}

/* switch按钮样式 */
::v-deep.switch {
  .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .el-switch__label--right span {
    margin-right: 11px;
  }

  /*关闭时文字位置设置*/
  .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .el-switch__label--left span {
    margin-left: 11px;
  }

  /*显示文字*/
  .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .el-switch__core, .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>
